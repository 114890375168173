<template>
  <MyView :meta="{ viewClass: 'view-messages', inFx: 'rightIn', bodyClass: 'view-messages-body' }" ref="MyView">
    <ViewHeader icon="arrowLeft" :title="$locale['words']['messages']" @back="goBack">
      <div slot="right" class="flex-center">
        <a class="view-header-button"><UserCreditsBtn /></a>
        <HamburgerBtn />
      </div>
    </ViewHeader>
    <ViewContent @scroll.native="scrolling">
      <div class="view-messages-content">
        <div class="view-messages-main main">
          <MessagesSearch :loading="loading" @search="search" :q="q" />
          <div class="view-messages-list">
            <MessagesUserCard v-for="(item, index) in contacts" :key="index" :item="item" />
            <ViewMoreBtn :morepage="morepage" :loading="loading" @click="getContacts((page += 1))" />
          </div>
        </div>
      </div>
    </ViewContent>
    <div class="chatViewMenu">
      <a class="button" @click="goBack"><Icon name="chevron-left" /></a>
    </div>
  </MyView>
</template>

<script>
export default {
  components: {
    MyView: () => import("../../views/View.vue"),
    ViewHeader: () => import("../view/ViewHeader.vue"),
    ViewContent: () => import("../view/ViewContent.vue"),
    HamburgerBtn: () => import("../home/HamburgerBtn.vue"),
    UserCreditsBtn: () => import("../user/UserCreditsBtn.vue"),
    ViewMoreBtn: () => import("../widgets/ViewMoreBtn.vue"),
    MessagesUserCard: () => import("./MessagesUserCard.vue"),
    MessagesSearch: () => import("./MessagesSearch.vue"),
  },
  data: function() {
    return {
      loading: false,
      page: 1,
      limit: 12,
      q: null,
      timeout: null,
      timeBuffer: 500,
    };
  },
  methods: {
    goBack: function() {
      this.$refs.MyView.close(() => {
        this.setQuery({ messages: undefined });
      });
    },
    search: function({ value }) {
      this.isLoading(true);
      this.q = value;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.getContacts(1);
      }, this.timeBuffer);
    },
    getContacts: async function(page) {
      let query = `${this.q ? `&q=${this.q}` : ""}`;
      const callback = () => this.isLoading(false);
      const error = (error) => this.ialert.go({ title: "Error", message: error });
      this.$store.dispatch("getContacts", { page, limit: this.limit, query, callback, error });
    },
    scrolling: function($event) {
      this.$scroller.isBottomScroll($event, () => {
        if (!this.loading && this.morepage) {
          this.getContacts((this.page += 1));
        }
      });
    },
  },
  computed: {
    contacts: function() {
      return this.$store.getters.contacts.users;
    },
    morepage: function() {
      return this.$store.getters.contacts.hasmore;
    },
  },
  beforeMount: function() {
    this.q = this.$store.getters.contacts.q;
    this.getContacts(1);
  },
};
</script>

<style lang="scss">
.view-messages {
  z-index: 11;
  &-body {
    &:not(.transparent) {
      background: #fff;
    }
  }
  &-list {
    margin-bottom: 49px;
  }
}
</style>
